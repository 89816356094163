<template>
  <div>
    <v-overlay
      :opacity="1"
      :value="overlay"
    >
      <v-progress-circular
        indeterminate
        size="200"
      >
        Een momentje aub...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from '@axios'

export default {
  data: () => ({
    someValue: 'hi',
    overlay: true,
  }),

  async mounted() {
    await this.wait(100)
    let pluginActiveReceived = false

    // Also listen to window messages
    window.addEventListener('message', event => {
      if (event.data.type === 'BP_PLUGIN_ACTIVE') {
        pluginActiveReceived = true
        console.log("Plug-in active received, let's redirect")
      }
    })

    // Read token cookie
    const tokenFromCookie = this.getCookie('bp_login_token')

    // Figure out which token to use (backwards compatibility), tokenFromCookie is preferred
    const token = tokenFromCookie || this.$route.params.id

    // Store the JWT token
    localStorage.setItem('accessToken', token)

    const jwt = token.split('.')[1]
    const decodedJwt = JSON.parse(window.atob(jwt))
    localStorage.setItem('exp', decodedJwt.exp)

    // Get account details
    const userRequest = await axios.get('/account/me')

    if (userRequest.status === 200) {
      const { account } = userRequest.data
      localStorage.setItem('userData', JSON.stringify(account))

      // Make sure the legacy login is executed (backwards compatibility)
      if (this.$route.params.authtype !== 'dynamic') {
        return this.legacyLogin(token)
      }

      // Check if we have to send the token to the plugin
      if (JSON.parse(this.getCookie('bp_login_strategy_push_token'))) {
        const data = { type: 'BP_JWT_TOKEN', jwt_token: this.$route.params.id }
        window.postMessage(data, '*')
      }

      // Get the redirect url
      const redirectUrl = decodeURIComponent(this.getCookie('bp_login_strategy_redirect_url'))

      // Remove the cookies
      document.cookie = 'bp_login_strategy_school_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      document.cookie = 'bp_login_strategy_group_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      document.cookie = 'bp_login_strategy_push_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      document.cookie = 'bp_login_strategy_redirect_url=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      document.cookie = 'bp_login_strategy_is_sub_strategy=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      document.cookie = 'bp_login_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

      // Before redirecting, make sure the plugin is active. Check for 5 seconds max
      let pluginActive = false

      // Check each 100ms if the plugin is active, max 5 seconds
      for (let i = 0; i < 50; i += 1) {
        if (pluginActiveReceived) {
          pluginActive = true
          break
        }

        // eslint-disable-next-line no-await-in-loop
        await this.wait(100)
      }

      // If the plug-in is not active, redirect to the noplugin page
      // Disable the plug-in active check for now
      pluginActive = true;
      window.location.href = pluginActive ? redirectUrl || 'https://portal.blokpass.be' : 'https://portal.blokpass.be/noplugin.html'
    }

    return true
  },

  methods: {
    async wait(delay) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, delay)
      })
    },
    getCookie(name) {
      const value = `; ${document.cookie}`
      const parts = value.split(`; ${name}=`)
      if (parts.length === 2) return parts.pop().split(';').shift()

      return null
    },
    async legacyLogin(token) {
      // First, send message to plugin
      const data = { type: 'BP_JWT_TOKEN', jwt_token: token }
      window.postMessage(data, '*')

      if (this.$route.params.authtype !== 'plugin') {
        console.log('gonna redirect to school picker')
        setTimeout(() => {
          this.$router.push('/school-picker').catch(err => {
            console.log(err)
          })
        }, 1000)
      } else {
        // Pupil plug-in login
        await this.wait(250)
        const getPortalUrlReponse = await axios.get('/portal', { headers: { Authorization: `Bearer ${token}` } })
        if (getPortalUrlReponse.status !== 200) {
          console.log('gonna push to default page')
          window.location.href = 'https://sites.google.com/blokje.info/speelenleer/start'

          return
        }

        const { url } = getPortalUrlReponse.data

        console.log('gonna push to ', url)
        window.location.href = url
      }
    },
  },
}
</script>
